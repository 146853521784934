import React from 'react';
import hatef from './hatef.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={hatef} className="App-logo" alt="logo" />
        <p>
          Welcome to my website!
        </p>
        <a
          className="App-link"
          href="https://gitlab.com/hateaf/resume/-/blob/master/cv.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click to see my resume!
        </a>
      </header>
    </div>
  );
}

export default App;
